import Image from 'next/image'
import type { FC } from 'react'

import type { PaymentMethodsComponentProps } from '../PaymentMethodsBlock'
import { List } from './styles'

export const BasicPaymentMethods: FC<PaymentMethodsComponentProps> = ({ paymentMethods }) => {
  return (
    <List>
      {paymentMethods?.map(method => (
        <Image alt={method.label} height="40" key={method.id} loading="lazy" src={method.image} width="120" />
      ))}
    </List>
  )
}
